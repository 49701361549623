import React, { useEffect } from 'react'
import { GlobalCSS } from '../GlobalCSS'
import { SnackbarProvider } from 'notistack'
import MuiThemeProvider from './MuiThemeProvider'
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

export default (props) => {
  useEffect(() => {
    // Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: 'AIzaSyC2uXBNWUVfRdHJu5CKfpVKfGdilHBTYNs',
      authDomain: 'website-f4875.firebaseapp.com',
      projectId: 'website-f4875',
      storageBucket: 'website-f4875.appspot.com',
      messagingSenderId: '144432377939',
      appId: '1:144432377939:web:93553cc95eb2d10e2f96a6',
      measurementId: 'G-TKCPHWF8FX',
    }
    // Initialize Firebase
    const app = initializeApp(firebaseConfig)
    const analytics = getAnalytics(app)
    window.analytics = analytics
  }, [])

  // const a = useStaticQuery(graphql`
  //   query($language: String!) {
  //     locales: allLocale(filter: { ns: { in: ["site"] }, language: { eq: $language } }) {
  //       edges {
  //         node {
  //           ns
  //           data
  //           language
  //         }
  //       }
  //     }
  //   }
  // `)
  // console.log(a)
  return (
    <MuiThemeProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <noscript>
          <iframe
            src='https://www.googletagmanager.com/ns.html?id=GTM-W3LHX7H'
            height='0'
            width='0'
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
        <GlobalCSS />
        {/* <Header /> */}
        {props.children}
      </SnackbarProvider>
    </MuiThemeProvider>
  )
}
