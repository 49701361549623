import { createTheme, ThemeProvider } from '@mui/material'
import { useLocation } from '@reach/router'
import React, { useMemo } from 'react'

export default ({ children }) => {
  const location = useLocation()

  const theme = useMemo(() => {
    const lang = location.pathname.split('/')[1]
    const language = /^ar|^en|^zh|^es-MX|^pl/.test(lang) ? lang : 'en'
    return createTheme({
      direction: language === 'ar' ? 'rtl' : 'ltr',
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 800,
          lg: 1280,
          xl: 1440,
        },
      },
    })
  }, [location.pathname])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
