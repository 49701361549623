exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-apply-page-tsx": () => import("./../../../src/pages/applyPage.tsx" /* webpackChunkName: "component---src-pages-apply-page-tsx" */),
  "component---src-pages-blog-404-tsx": () => import("./../../../src/pages/blog/404.tsx" /* webpackChunkName: "component---src-pages-blog-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-calculation-quotations-index-tsx": () => import("./../../../src/pages/calculationQuotations/index.tsx" /* webpackChunkName: "component---src-pages-calculation-quotations-index-tsx" */),
  "component---src-pages-careers-detail-tsx": () => import("./../../../src/pages/careersDetail.tsx" /* webpackChunkName: "component---src-pages-careers-detail-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-cookie-policy-brazil-tsx": () => import("./../../../src/pages/cookie-policy-Brazil.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-brazil-tsx" */),
  "component---src-pages-cookie-policy-en-index-tsx": () => import("./../../../src/pages/cookie-policy-en/index.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-en-index-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-cooperate-csp-tsx": () => import("./../../../src/pages/cooperate/CSP.tsx" /* webpackChunkName: "component---src-pages-cooperate-csp-tsx" */),
  "component---src-pages-csp-channel-service-partner-tsx": () => import("./../../../src/pages/csp/channelServicePartner.tsx" /* webpackChunkName: "component---src-pages-csp-channel-service-partner-tsx" */),
  "component---src-pages-csp-enquiry-form-tsx": () => import("./../../../src/pages/csp/enquiryForm.tsx" /* webpackChunkName: "component---src-pages-csp-enquiry-form-tsx" */),
  "component---src-pages-csp-how-to-apply-tsx": () => import("./../../../src/pages/csp/howToApply.tsx" /* webpackChunkName: "component---src-pages-csp-how-to-apply-tsx" */),
  "component---src-pages-delete-account-tsx": () => import("./../../../src/pages/deleteAccount.tsx" /* webpackChunkName: "component---src-pages-delete-account-tsx" */),
  "component---src-pages-enquired-success-tsx": () => import("./../../../src/pages/enquiredSuccess.tsx" /* webpackChunkName: "component---src-pages-enquired-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-collection-tsx": () => import("./../../../src/pages/information-collection.tsx" /* webpackChunkName: "component---src-pages-information-collection-tsx" */),
  "component---src-pages-legal-notice-tsx": () => import("./../../../src/pages/legal-notice.tsx" /* webpackChunkName: "component---src-pages-legal-notice-tsx" */),
  "component---src-pages-legal-notice-uk-index-tsx": () => import("./../../../src/pages/legal-notice-uk/index.tsx" /* webpackChunkName: "component---src-pages-legal-notice-uk-index-tsx" */),
  "component---src-pages-pay-gate-detail-tsx": () => import("./../../../src/pages/payGate/detail.tsx" /* webpackChunkName: "component---src-pages-pay-gate-detail-tsx" */),
  "component---src-pages-pay-gate-result-tsx": () => import("./../../../src/pages/payGate/result.tsx" /* webpackChunkName: "component---src-pages-pay-gate-result-tsx" */),
  "component---src-pages-privacy-agreement-page-tsx": () => import("./../../../src/pages/privacyAgreementPage.tsx" /* webpackChunkName: "component---src-pages-privacy-agreement-page-tsx" */),
  "component---src-pages-privacy-policy-brazil-index-tsx": () => import("./../../../src/pages/privacy-policy-Brazil/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-brazil-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-policy-uk-index-tsx": () => import("./../../../src/pages/privacy-policy-uk/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-uk-index-tsx" */),
  "component---src-pages-prohibited-parcels-tsx": () => import("./../../../src/pages/prohibited-parcels.tsx" /* webpackChunkName: "component---src-pages-prohibited-parcels-tsx" */),
  "component---src-pages-requestaquote-tsx": () => import("./../../../src/pages/requestaquote.tsx" /* webpackChunkName: "component---src-pages-requestaquote-tsx" */),
  "component---src-pages-service-international-tsx": () => import("./../../../src/pages/service/international.tsx" /* webpackChunkName: "component---src-pages-service-international-tsx" */),
  "component---src-pages-service-logistics-products-detail-tsx": () => import("./../../../src/pages/service/logisticsProductsDetail.tsx" /* webpackChunkName: "component---src-pages-service-logistics-products-detail-tsx" */),
  "component---src-pages-service-logistics-products-tsx": () => import("./../../../src/pages/service/logisticsProducts.tsx" /* webpackChunkName: "component---src-pages-service-logistics-products-tsx" */),
  "component---src-pages-service-what-we-do-tsx": () => import("./../../../src/pages/service/whatWeDo.tsx" /* webpackChunkName: "component---src-pages-service-what-we-do-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-pages-term-of-use-uk-index-tsx": () => import("./../../../src/pages/term-of-use-uk/index.tsx" /* webpackChunkName: "component---src-pages-term-of-use-uk-index-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-track-tsx": () => import("./../../../src/pages/track.tsx" /* webpackChunkName: "component---src-pages-track-tsx" */),
  "component---src-templates-blog-detail-tsx": () => import("./../../../src/templates/blogDetail.tsx" /* webpackChunkName: "component---src-templates-blog-detail-tsx" */)
}

