import { CSSObject } from '@emotion/react'

const fontFace = (
  fontFamily: string,
  fontFilePrefix: string,
  style: CSSObject['fontStyle'],
  weight: CSSObject['fontWeights'],
  type: string,
) => {
  return `
    @font-face {
      font-display: swap;
      font-family: ${fontFamily};
      font-style: ${style};
      font-weight: ${weight};
      src: local("${fontFilePrefix}-${type}"),
        url('/font/${fontFilePrefix}-${type}.woff2') format('woff2'),
        url('/font/${fontFilePrefix}-${type}.woff') format('woff'),
        url('/font/${fontFilePrefix}-${type}.ttf') format('truetype');
    }
  `
}

const getFontFaces = (fontFamily: string, fontFilePrefix: string, ...fontFaces: any[][]) => {
  return fontFaces.reduce((memo, a) => {
    return memo + fontFace(fontFamily, fontFilePrefix, ...a)
  }, '')
}

export const getFontFacesCss = (fontFamily: string, fontFilePrefix: string) =>
  getFontFaces(
    fontFamily,
    fontFilePrefix,
    ['normal', 100, 'Thin'],
    ['italic', 100, 'ThinItalic'],
    ['normal', 200, 'ExtraLight'],
    ['italic', 200, 'ExtraLightItalic'],
    ['normal', 300, 'Light'],
    ['italic', 300, 'LightItalic'],
    ['normal', 'normal', 'Regular'],
    ['normal', 400, 'Regular'],
    ['italic', 'normal', 'Italic'],
    ['italic', 400, 'Italic'],
    ['normal', 500, 'SemiBold'],
    ['italic', 500, 'SemiBoldItalic'],
    ['normal', 'bold', 'Bold'],
    ['normal', 600, 'Bold'],
    ['italic', 600, 'BoldItalic'],
    ['normal', 700, 'Medium'],
    ['italic', 700, 'MediumItalic'],
    ['normal', 800, 'ExtraBold'],
    ['italic', 800, 'ExtraBoldItalic'],
  )
